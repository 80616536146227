import React from "react"

export default function Detailed_info(props) {

  return (
  <>
 <p><h3>Classes & Workshops</h3>
    <h4>Yoga & pranayama</h4></p>
    <p>To honor the uniqueness of each individual, we’ll be offering different tools and options throughout the class. Each person will have the opportunity to follow their own rhythm and intensity required.
    </p><p>The yoga asana practices will be Vinyasa-based with a gentle flow. We’ll focus especially on listening to our body and tuning in to what we need. Over the course of these three days we will explore the qualities of grounding, balance and heart opening.
    </p><p>This retreat is ideal for people with a basic knowledge of yoga asana.
    </p><p>In addition, Pranayama, the focus and control of breath, will be included in the yoga classes and meditations.
    </p>
    <p><h4>Meditation</h4></p>
<p>
    The deep breathing of meditation lowers heart rates and regulates blood pressure. When we focus and turn our attention inwards, we calm our mind and can reduce levels of stress and anxiety.

   </p><p> The meditation in this retreat will be Buddhist-style based, helping to develop mindfulness, insight and tranquility.</p>
    <p><h4>Yoga Nidra</h4>
    </p><p>Yoga nidra offers a space to explore what you need at the moment, as well as an opportunity to work on releasing long-held emotions.

   </p><p> In yoga nidra, or yogic sleep, we will rest comfortably in (variations of) savasana (corpse pose), while you are guiding our awareness to different parts of the body.<br/> The stages of body scan and breath awareness alone can be practiced to calm the nervous system, leading to less stress and better health. This gentle practice, while simple, promotes deep rest and relaxation.</p>
         </>
  )
}
