import React from 'react'

const Details = () => (
  <>
    <div className='text-light'>
    <h3 className='pt-5'>Address - Quinta Das Vinhas</h3>
      <p>
      Estrada Regional 223 N136<br></br>
      Calheta Madeira 9370-237<br></br>
      Portugal
      </p>
      <p><h3>Phone - Pranapnea</h3><a href="tel:+351960311725">(+351) 960 311 725</a></p>
      <h3>Email</h3><a href='mailto:info@pranapnea.com'>info@pranapnea.com</a>
    </div>
  </>
)
export default Details
