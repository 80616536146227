import React from "react"

export default function Detailed_info2(props) {

  return (
  <>
    <h2>Sacred Circle</h2>
    <p>In our circle, you will be guided through a unique lens and journey of connection through the body and breath, through words and play.</p>
    <p>To help your experience a deep sense of inner safety, we will explore: which ways may help us remain calm in our most challenging moments?</p>
    <p>Our busy day-to-day lifestyles so often leave us feeling frazzled or overwhelmed by continual tasks and busy schedules, which impact our quality-of-life and our relationships.</p>
    <p>This sacred circle will help you switch on a new psychological muscle that helps return you to a connected place to feel grounded, in control and having more compassion for the process.</p>
    <p>At the heart of this circle are SOMA Breath work and authentic-relating games. This circle invites your simple curiosity and willingness to try on different experiences as you give yourself full permission to play, receive and share from the heart.</p>
    <h2>Ayurveda&nbsp;</h2>
    <p><strong>The menu </strong>served during your retreat is based on ayurveda, mainly vegetarian with some fish options. The menu is aligned with all the different dosha types with options for altering it to your specific dosha.</p>
    <p>The <strong>Ayurvedic lifestyle &amp; diet workshop </strong>will help you understand the core of your unique mental &amp; physical nature + discover simple, sustainable changes you can make which will allow your energy to flow in its most natural way. Amongst other, we will focus on Lifestyle and diet.&nbsp;</p>
    <p>You&rsquo;ll discover what simple things you can do at work and in your personal time to cultivate a life that feels balanced and supports your true nature. Because the more aligned our lifestyle is, the more energy, vitality, gratitude and joy we experience&mdash;naturally.&nbsp;</p>
    <p>In addition, you&rsquo;ll discover what types of food, including tastes, temperatures, herbs and spices will help you feel more like you, given who you are and what&rsquo;s going on in your life right now.</p>
    <p>Lastly, you&rsquo;ll receive a <strong>1</strong><strong>-</strong><strong>to</strong><strong>-</strong><strong>1 Ayurveda consultation </strong>will be provided to all participants after the retreat, via Zoom, at a time of your choosing, so you can learn how to support yourself in the best possible way to honor your unique nature and your unique lifestyle back at home.</p>
    <h2>Movement workshop</h2>
    <p>In this workshop, you&rsquo;ll have a chance to discover different tools of improvisation and composition to expand your creative capacities and enhance your own creative processes. We will use visualization and simple breath techniques to connect the mind and body and stimulate the imagination. This creates a nourishing ground for exploring different states of mind and physical qualities.</p>
 </>
 )
}
