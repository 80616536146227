import React from "react"
import { Row, Table } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Schedule from './schedule_tfl'
import Summary from './summary_tfl'
import Detailed_info from './detailed_info'
import Detailed_info2 from './detailed_info2'
import Practical_details from './practical_details'


export default function Home(props) {

  const flyer = "../../images/toolsforlife/flyer_TFL.jpg"
  const below_flyer = "../../images/toolsforlife/DSC00880.jpg"

  return (
    <section>
      <div className="mt-5">
        <Row>
          <div className=" mb-5 col-sm ms-1 me-1 ">
            <h1>Nurture Your Soul</h1>
            <Summary/>
            <StaticImage
              src={below_flyer}
              alt="Flyer Tools For Life"
              placeholder="blurred"
              layout="constrained"
              height={1000}
              quality={100}
            />
            <br/><br/>
          </div>
          <div className=" mb-5 col-sm ms-1 me-1 ">
            <StaticImage
              src={flyer}
              alt="Flyer Tools For Life"
              placeholder="blurred"
              layout="constrained"
              height={1000}
              quality={100}
            />
            <br/><br/>
            <Detailed_info/>
          </div>
        </Row>
      <Detailed_info2/>
      <Schedule/>
      <Practical_details/>
    </div>
  </section>
  )
}
