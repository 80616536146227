import React from "react"
import heroPoster from "../../images/toolsforlife/DSC00847.jpg"

export default function HeroVid(props) {


    if (props.littleScreen === "1"){
        return (
        <video poster={heroPoster}>
        </video>
        )
    }
    else {
        return (
            <video poster={heroPoster} muted autoPlay playsInline loop>
            </video>
         )
    }
}
