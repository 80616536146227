import React from "react"
import { Row, Card} from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"


export default function Staff(props) {

    const sarah = "../../images/toolsforlife/sarah.jpg"
    const mel_front = "../../images/toolsforlife/test.jpg"
    const sabi = "../../images/toolsforlife/DSC00809.jpg"
    const justin = "../../images/toolsforlife/DSC00728.jpg"

  return (
	  <>
	  <section>
        <h1 className="text-light pt-5">Your Teachers</h1>
		<Row>
    <Card className="shadow mb-5 col-sm ms-1 me-1 bg-transparent">
            <Card.Header><h3>Sabine Molenaar</h3></Card.Header>
                <StaticImage
                    src={sabi}
                    alt="Sabi Yoga Instructor"
                    placeholder="blurred"
                    layout="constrained"
                    height={600}
                    quality={100}
                    className="teacher_pic"
                />

                <Card.Body>
                    <Card.Title className="mb-2 text-light">Yoga Teacher & Dance Artist</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">Vinyasa, Yin, Prenatal yoga and Choreographer</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>Conscious and creative movement has been Sabine&rsquo;s lifelong path. As being a professional dancer all her life and a choreographer in the last decade, the connection between mind and body became an essential for her, with breath forming the bridge between these two.</p>

<p>Studying yoga only was a natural expansion of that fascination and since it became part of her life she keeps on deepening her knowledge and insight. Amongst others, she studied and continues to study Vinyasa yoga, Yin yoga, Hatha yoga, prenatal yoga and Thai yoga massage.</p>

<p>Sabine is currently living in Madeira with her man and two kids, where amongst others they created Pranapnea, to share their joy and knowledge of Yoga and Freediving.</p>                    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:sabine@pranapnea.com">sabine@pranapnea.com</a></Card.Subtitle>
                    <Card.Subtitle className="mt-2 text-light">Tel : <a href="tel:+351960311725">+351 960 311 725</a></Card.Subtitle>
                </Card.Body>
			</Card>
			<Card className="shadow mb-5 col-sm ms-1 me-1 bg-transparent">
            <Card.Header><h3>Justin Robertshaw</h3></Card.Header>
                <StaticImage
                    src={justin}
                    alt="Justin"
                    placeholder="blurred"
                    layout="constrained"
                    height={600}
                    quality={100}
                    className="teacher_pic"
                />

                <Card.Body>
                    <Card.Title className="mb-2 text-light">Lifestyle coach</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">Ayurveda, yoga and permaculture</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>Justin followed the one thing that had brought the most meaning and inspiration to him&mdash;his yoga practice&mdash;and through a daily practice, over the past 18 years, he found his other passions of Ayurveda and Permaculture.</p>

<p>Interweaving Yoga, Ayurveda, and Permaculture knowledge, he now helps people understand and experience who they really are, (their physical and mental constitution) and how to create and live a life in alignment and balance in a simple and practical way</p>
    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:
justin@justinrobertshaw.com">justin@justinrobertshaw.com</a></Card.Subtitle>
                    <Card.Subtitle className="mt-2 text-light">Website : <a href="https://www.justinrobertshaw.com">https://www.justinrobertshaw.com</a></Card.Subtitle>
                </Card.Body>
			</Card>
    </Row>
 <Row>
            <Card className="shadow mb-5 col-sm me-1 ms-1 bg-transparent">
            <Card.Header><h3>Mel Howard-Dobson</h3></Card.Header>
			      <StaticImage
                    src={mel_front}
                    alt="Mel Howard-Dobson"
                    placeholder="blurred"
                    layout="constrained"
                    height={400}
                    quality={100}
                    className="teacher_pic"
                />
                <Card.Body>
                    <Card.Title className="mb-2 text-light">Breathwork Coach & Circle Facilitator</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">SOMA Breath Work, Hypnobirthing, Space Holder</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>Mel spent the last twenty years of her life working in various healing modalities of the body and now as a breath-coach, letting her voice guide people even more, to help transform their struggles and reach deeper states of calm and peace. And to experience themselves and their own gifts through deeper states of relaxation in the body and mind. She helps people catalyze their inner greatness and unshackle themselves from past limitations, reclaiming their inner harmony, empowered life force and joy inside.</p>

<p>Her experiences range from working in mental health offering holistic therapies and mindfulness, to running her own business as hypnobirthing teacher, (hypnosis and relaxation techniques) for optimal birthing, also attending births as a doula.</p>

<p>She currently teaches SOMA Breath work here in Madeira for humans of all ages helping people tap into their inner pharmacy to balance their bodies naturally, which leads to a more resilient mindset to combat stress and fatigue, shifting perception out of any habitual survival states and into thriving and abundance within.</p>

<p>She loves being in nature with like-minded community, and especially if that involves being by a fire, listening to good stories with drumming and singing under the stars.</p>                    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:melanie@rebirthandrise.com">melanie@rebirthandrise.com</a></Card.Subtitle>
                    <Card.Subtitle className="mt-2 text-light">Website : <a href="https://rebirthandrise.com">https://rebirthandrise.com</a></Card.Subtitle>
                </Card.Body>
			</Card>
            <Card className="shadow mb-5 col-sm me-1 ms-1 bg-transparent">
            <Card.Header><h3>Sarah Faye</h3></Card.Header>
			      <StaticImage
                    src={sarah}
                    alt="sarah"
                    placeholder="blurred"
                    layout="constrained"
                    height={400}
                    quality={100}
                    className="teacher_pic"
                />
                <Card.Body>
                    <Card.Title className="mb-2 text-light">Yoga Teacher</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">Meditation, Hatha, SUP and Arial yoga.</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>Sarah is a wellness enthusiast who cares about creating safe and non-judgmental spaces for healing and self-reflection.  She studies and facilitates Buddhist inspired meditations, aerial Yoga, and stand-up-paddle board Yoga and loves to include nature in her therapies as an aid for self-discovery and joy.</p>

<p>Sarah is passionate about creating and supporting genuine communities where people feel inspired, secure, and free to be their authentic and unique selves. She has participated in many meditation and yoga retreats worldwide where chances to get to know herself and others intimately in a shared space were incredibly transformative, and she wishes to bring that feeling and experience to others!</p>

<p>Sarah has been engaged in the Tibetian Mahayana Buddhist community since 2014 and facilitates contemplative meditations focusing on how to transform the mind towards peace and contentment in daily life. Sarah is formally in graduate school studying counseling/psychotherapy and adventure/nature-based therapies.</p>
<p>She is a Florida native and resides permanently on Madeira island with her husband and son.</p>
                    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:lightvibrations90@gmail.com">lightvibrations90@gmail.com</a></Card.Subtitle>
                </Card.Body>
			</Card>
      </Row>
		</section>
		</>
    )
}
