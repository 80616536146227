import React from "react"

export default function Summary(props) {

  return (
  <>
  <h2>About Your Retreat</h2>
  <p>Take a pause — just for a weekend — to refill your cup and learn how to more easily stay nourished in ways that fit seamlessly with your personal lifestyle.</p>
  <p>This retreat is an opportunity to take the time, space and support to really ask yourself:</p>
  <li class="ms-3">How am I, in this moment?</li>
  <li class="ms-3">What do I truly need, right now?</li>
  <br/>
  <p>Collect the tools, skills, and understanding you need to be able to answer those questions +give yourself what you need, in any moment, once you’re back at home.</p>
  <p>
     <h4>For 3 days, enjoy</h4>
      <li class="ms-3">Delicious, healthy food made for you</li>
      <li class="ms-3">Supportive mind-body practices (yoga, pranayama, breathwork, meditation, & movement)</li>
      <li class="ms-3">Group workshops as well as 1-on-1 guidance</li>
      <li class="ms-3">Time in the natural, deeply nourishing environment of Madeira</li>
  </p>
  <p>
    <h4>Learn how to</h4>
      <li class="ms-3">Assess how you’re doing from an Ayurvedic point of view</li>
      <li class="ms-3">Discern what’s needed to help you to come back into balance and refill your cup</li>
      <li class="ms-3">Apply simple techniques and practices to help you create an immediate shift in your energy + provide long-term results in your life as a whole.</li>
  </p>
  <p>
      <h4>Come away with</h4>
      <li class="ms-3">The clarity, skills, and tools you need in order to feel much more nourished in your everyday life</li>
      <li class="ms-3">A toolkit of supportive + practical (5-minute) practices which you can draw on in any moment</li>
      <li class="ms-3">Connections with friends from all over the world who are here to encourage and support you</li>
  </p>
  </>
  )
}

