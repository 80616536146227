import React from "react"
import { Row, Table } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

export default function Schedule(props) {
  const flyer = "../../images/toolsforlife/flyer_TFL.jpg"
  const below_flyer = "../../images/toolsforlife/DSC00880.jpg"

  return (
  <>
  <br id='schedule'></br>
  <h3>Retreat Schedule</h3>
  <div>
    <Table striped bordered hover variant="dark">
      <thead>
        <tr class="table-primary">
          <th>Time Table</th>
          <th>Friday</th>
            <th>Saturday</th>
            <th>Sunday</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>7.15</td>
            <td></td>
            <td class="table-success">Mini Meditation or Pranayama</td>
            <td class="table-success">Mini Meditation or Pranayama</td>
          </tr>
          <tr>
            <td>7.30</td>
            <td></td>
            <td>Light Breakfast  tea snack</td>
            <td>Light Breakfast tea snack</td>
          </tr>
          <tr>
            <td>8.00</td>
            <td></td>
            <td class="table-warning">Gentle Morning Yoga</td>
            <td class="table-warning">Gentle Morning Yoga</td>
          </tr>
          <tr>
            <td>9.15</td>
            <td>Arrival</td>
            <td>Breakfast</td>
            <td>Breakfast</td>
          </tr>
          <tr>
            <td>10.30</td>
            <td>Arrival CheckIn and Introduction</td>
            <td class="table-danger">Ayurveda Workshop</td>
            <td class="table-danger">Movement Workshop</td>
          </tr>
          <tr>
            <td>12.15</td>
            <td class="table-success">Mini Meditation or Pranayama</td>
            <td class="table-success">Mini Meditation or Pranayama</td>
            <td class="table-danger">Movement Workshop</td>
          </tr>
          <tr>
            <td>12.30 - 13.30</td>
            <td>Lunch</td>
            <td>Lunch</td>
            <td>Lunch</td>
          </tr>
          <tr>
            <td>13.30</td>
            <td>Massage Option / Free Time</td>
            <td class="table-info">SOMA Breath Work</td>
            <td>Massage Option / Free Time</td>
          </tr>
          <tr>
            <td>15.30</td>
            <td class="table-warning">Yoga and Pranayama</td>
            <td class="table-warning">Yoga and Pranayama</td>
            <td class="table-success">End Meditation and Yoga Nidra</td>
          </tr>
          <tr>
            <td>18.00</td>
            <td>Dinner</td>
            <td>Dinner</td>
            <td>Departures</td>
          </tr>
          <tr>
            <td>19.00 - 21.00</td>
            <td class="table-danger">Sacred Circle</td>
            <td class="table-success">Meditation and Yoga Nidra</td>
            <td></td>
          </tr>
          <tr>
            <td>Special - Extra</td>
              <td>Private Individual Ayurvedic Consultation</td>
              <td>With Justin</td>
              <td>To be personally scheduled</td>
          </tr>
        </tbody>
      </Table>
  </div>
  </>
  )
}
