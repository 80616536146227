import React from "react"
import * as landingStyle from "../../styles/landingStyle.css"
import scrollTo from 'gatsby-plugin-smoothscroll'
import HeroVid from '../toolsforlife/heroVid_tfl'
import { Button, Row } from 'react-bootstrap'
import { useState, useEffect } from 'react'

export default function LandingPage(props) {

  const [smallScreen, setSmallScreen] = useState(false)

  useEffect(() => {
    const handleSize = () => {
      const screenLarge = window.innerWidth > 768

      if (screenLarge) {
        setSmallScreen(false)
      }
      else {
        setSmallScreen(true)
      }
    }
    window.addEventListener("resize", handleSize, false);

    // Call this right away so state gets updated with initial window size (No need for the default State function)
    handleSize();

    return () => {
      window.removeEventListener("resize", handleSize, false);
    }
  }, [])


  return (
    <>

    <section style={{...landingStyle}} className="showcase" id="landing">
      { smallScreen ? <HeroVid littleScreen="1" /> : <HeroVid/> }
      <div className="overlay"></div>
      <div className="slogan_tfl">
        <h2>Nurture Your Soul</h2>
        <svg className="svgContained" viewBox="0 0 452 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 3.0187C267 1.5187 448.5 91.0187 448.5 3.0187" stroke="#ffffff" stroke-width="6"/>
        </svg>
        <h4>A weekend - just for you - to ground, center, and nourish yourself</h4>
        <Button className="custom-btn" onClick={() => scrollTo('#home')}>Tell me more</Button>
      </div>
      </section>

    </>
  )
}
