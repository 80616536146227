//React or Gatsby plugins
import React from "react";
import { Container } from 'react-bootstrap';
//Components
import Navbar from '../components/toolsforlife/navbar_tfl'
import Home from '../components/toolsforlife/home_tfl'
import LandingPage from '../components/toolsforlife/landingPage_tfl'
import Staff from '../components/toolsforlife/staff_tfl'
import Contact from '../components/toolsforlife/contact_tfl'
//Styles
import * as indexStyle from '../styles/indexStyle.css'

export default function Index({ data }) {

return (
<>
  <html lang='en'>
    <body>
    <Navbar />
    <LandingPage />
      <Container className='sb' style={{...indexStyle}}>
        <br id='home'></br>
        <Home />
        <br id='staff'></br>
        <Staff />
        <br id='contact'></br>
        <Contact />
        <br></br>
      </Container>
    </body>
    <footer>
    <br></br>
    </footer>
  </html>
</>
)
}
