import React from "react"
import { Nav, Button, Table} from 'react-bootstrap';
import scrollTo from 'gatsby-plugin-smoothscroll';

export default function Summary(props) {

  return (
  <>
  <h1>Retreat location</h1>
  <p>This retreat takes place in the charming Quinta das Vinhas, a small informal vineyard hotel on the sunny south coast of Madeira island.
  <br/>Quinta das Vinhas spreads out over 30,000 sq m, most of which is occupied with an organic vineyard.</p>
  <p>For a fully immersive experience, you have the opportunity to stay in the main house of the Quinta, which dates back to the 17th century.<br/>
    Six rooms available in the main house, and separate cottages on the property are available as well, once the main house is booked out.</p>
  <p>You are welcome to join the retreat with 2 nights accommodation in the Quinta or in accommodations of your own.</p>
  <p>To learn more about the Quinta, visit : <a href="https://www.qdvmadeira.com/">qdvmadeira.com</a></p>
  <h3>Prices</h3>
  <div class="ms-2 me-2">

    <Table striped bordered hover variant="dark">
      <thead>
        <tr class="table-primary">
          <th>Full Programme + 2 nights stay at the Quinta</th>
          <th>Full Programme</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <u>Includes:</u>
              <br/><br/>
              <li class="ms-3">Daily yoga, meditation & pranayama</li>
              <li class="ms-3">3 x group workshops</li>
              <li class="ms-3">1 x private Ayurveda consultation</li>
              <li class="ms-3">Healthy meals with local and seasonal ingredients</li>
              <li class="ms-3">Free time / time for massages</li>
              <li class="ms-3">Nourishment take-aways: Recorded guided meditation, Recorded breathwork session, and 1 Ayurvedic recipe</li>
              <li class="ms-3">2 nights stay at the Quinta das Vinhas</li>
              <br/>
              <Button onClick={() => scrollTo('#contact')}>Full programme + single room: &euro;540</Button><br/><br/>
<Button onClick={() => scrollTo('#contact')}>Full programme + shared room*: &euro;450</Button>
              <br/><br/>
              <p>*If you’d like to share a room but you don’t have a partner or friend attending, you can ask us to match you with another participant, if there are other requests.</p>
            </td>
            <td>
              <u>includes:</u>
              <br/><br/>
              <li class="ms-3">Daily yoga, meditation & pranayama</li>
              <li class="ms-3">3 x group workshops</li>
              <li class="ms-3">1 x private Ayurveda consultation</li>
              <li class="ms-3">Healthy meals with local and seasonal ingredients</li>
              <li class="ms-3">Free time / time for massages</li>
              <li class="ms-3">Nourishment take-aways: Recorded guided meditation, Recorded breathwork session, and 1 Ayurvedic recipe</li>
              <br/>
              <Button onClick={() => scrollTo('#contact')}>Full programme: &euro;360</Button><br/>
              <br/><br/>
              <p>We kindly ask all non-boarders to attend the full programme so that everyone can enjoy the full community experience together.</p>
            </td>
          </tr>
        </tbody>
      </Table>
      <p>Please note, prices do not include:<br/>
    <li class="ms-3">Airport tickets nor transfer from.</li>
    <li class="ms-3">Transfers to the Quinta may be arranged if required.</li>
    <li class="ms-3">Massages - to be requested and paid separately to the massage therapists.</li></p>
    </div>
  </>
  )
}
